import IO from "socket.io-client";
import config from "./config";
import Value from "./primitives/value";
import Obj from "./primitives/object";
import List from "./primitives/list";

export default class HamoniSync {
  constructor(token) {
    if (!token)
      throw new Error("token is needed to initiliase Hamoni Sync SDK");
    this._token = token;
    this._reconnectedCallbacks = [];
    this._disconnectedCallbacks = [];
    this._appId = "";
  }

  connect() {
    return new Promise((resolve, reject) => {
      this._io = IO(config.serverUrl, {
        query: {
          sdktoken: this._token
        }
      });

      this._io.once("connect", () => {
        this._io.emit("get-id", "", response => {
          this._appId = response;
          resolve();
        });
      });

      this._io.once("connect_error", error => reject(error));

      this._io.once("error", error => reject(error));

      this._io.on("reconnect", () =>
        this._reconnectedCallbacks.forEach(cb => cb())
      );

      this._io.on("disconnect", reason => {
        let msg = "";
        if (reason === "io client disconnect") msg = "client disconnect";
        else msg = "server unavailable";
        this._disconnectedCallbacks.forEach(cb => cb(msg));
      });
    });
  }

  createValue(name, state) {
    return new Promise((resolve, reject) => {
      if (state === null || state === undefined)
        reject("State cannot be empty");
      else if (typeof state === "function" || typeof state === "object")
        reject("State has an invalid type");
      else {
        this._io.emit(
          "create-value",
          { name: name, state: state },
          response => {
            if (response.error) reject(response.error);
            else {
              //resolve with an instance of value primitive
              resolve(new Value(this._appId, this._io, name, response));
            }
          }
        );
      }
    });
  }

  createObject(name, state) {
    return new Promise((resolve, reject) => {
      if (typeof state !== "object" || state === null || state === undefined) {
        reject("State is invalid");
      } else {
        this._io.emit(
          "create-object",
          { name: name, state: state },
          response => {
            if (response.error) reject(response.error);
            else {
              //resolve with an instance of value primitive
              resolve(new Obj(this._appId, this._io, name, response));
            }
          }
        );
      }
    });
  }

  createList(name, state) {
    return new Promise((resolve, reject) => {
      if (
        state.constructor.name !== "Array" ||
        state === null ||
        state === undefined
      ) {
        reject("State is invalid");
      } else if (state.length < 1) {
        reject("State cannot be an empty array");
      } else {
        this._io.emit("create-list", { name: name, state: state }, response => {
          if (response.error) reject(response.error);
          else {
            resolve(new List(this._appId, this._io, name, response));
          }
        });
      }
    });
  }

  get(name) {
    return new Promise((resolve, reject) => {
      if (name && typeof name === "string") {
        this._io.emit("get-state", name, response => {
          if (response.error) reject(response.error);
          else {
            //resolve with an instance of primitive type
            if (response.type === "value")
              resolve(new Value(this._appId, this._io, name, response));
            else if (response.type === "object")
              resolve(new Obj(this._appId, this._io, name, response));
            else if (response.type === "list")
              resolve(new List(this._appId, this._io, name, response));
          }
        });
      } else {
        reject("invalid state name passed as parameter");
      }
    });
  }

  onReconnected(cb) {
    if (typeof cb !== "function")
      throw Error("Expected a function but got an invalid parameter type");
    this._reconnectedCallbacks.push(cb);
  }

  onDisconnected(cb) {
    if (typeof cb !== "function")
      throw Error("Expected a function but got an invalid parameter type");
    this._disconnectedCallbacks.push(cb);
  }
}
