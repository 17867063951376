export default class Obj {
  constructor(appId, io, name, state) {
    this._io = io;
    this._stateName = name;
    this._state = state;
    this._UpdateCallbacks = [];
    this._syncCallbacks = [];

    this._io.on(`object-updated-${appId}-${name}`, state => {
      this._state = state;
      this._UpdateCallbacks.forEach(cb => cb(state.data));
    });

    this._io.on("reconnect", () => {
      this._io.emit("get-state", this._stateName, response => {
        if (!response.error) {
          if (response.rev !== this._state.rev) {
            this._state = response;
            this._syncCallbacks.forEach(cb => cb(response.data));
          }
        }
      });
    });
  }

  update(newState) {
    if (
      typeof newState !== "object" ||
      newState === null ||
      newState === undefined
    )
      throw Error("State is invalid");
    else {
      this._io.emit("update-object", {
        name: this._stateName,
        rev: this._state.rev,
        state: newState
      });
    }
  }

  onUpdated(cb) {
    if (typeof cb !== "function")
      throw Error("Expect a function but got an invalid parameter type");
    this._UpdateCallbacks.push(cb);
  }

  onSync(cb) {
    if (typeof cb !== "function")
      throw Error("Expected a function but got an invalid parameter type");
    this._syncCallbacks.push(cb);
  }

  get() {
    return this._state.data;
  }
}
