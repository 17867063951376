export default class List {
  constructor(appId, io, name, state) {
    this._io = io;
    this._stateName = name;
    this._state = state;
    this._itemUpdatedCallbacks = [];
    this._itemRemovedCallbacks = [];
    this._itemAddedCallbacks = [];
    this._resetCallbacks = [];
    this._syncCallbacks = [];

    this._io.on(`item-updated-${appId}-${name}`, state => {
      this._state.rev = state.rev;
      this._state.data[state.data.index] = state.data.value;
      this._itemUpdatedCallbacks.forEach(cb => cb(state.data));
    });

    this._io.on(`item-deleted-${appId}-${name}`, state => {
      this._state.rev = state.rev;
      this._state.data.splice(state.data.index, 1);
      this._itemRemovedCallbacks.forEach(cb => cb(state.data));
    });

    this._io.on(`item-added-${appId}-${name}`, state => {
      this._state.rev = state.rev;
      this._state.data[state.data.index] = state.data.value;
      this._itemAddedCallbacks.forEach(cb => cb(state.data));
    });

    this._io.on(`list-reset-${appId}-${name}`, state => {
      this._state.rev = state.rev;
      this._state.data = state.data;
      this._resetCallbacks.forEach(cb => cb(state.data));
    });

    this._io.on("reconnect", () => {
      this._io.emit("get-state", this._stateName, response => {
        if (!response.error) {
          if (response.rev !== this._state.rev) {
            this._state = response;
            this._syncCallbacks.forEach(cb => cb(response.data));
          }
        }
      });
    });
  }

  onReset(cb) {
    if (typeof cb !== "function")
      throw Error("Expected a function but got an invalid parameter type");
    this._resetCallbacks.push(cb);
  }

  onItemAdded(cb) {
    if (typeof cb !== "function")
      throw Error("Expected a function but got an invalid parameter type");
    this._itemAddedCallbacks.push(cb);
  }

  onItemUpdated(cb) {
    if (typeof cb !== "function")
      throw Error("Expected a function but got an invalid parameter type");
    this._itemUpdatedCallbacks.push(cb);
  }

  onItemRemoved(cb) {
    if (typeof cb !== "function")
      throw Error("Expected a function but got an invalid parameter type");
    this._itemRemovedCallbacks.push(cb);
  }

  onSync(cb) {
    if (typeof cb !== "function")
      throw Error("Expected a function but got an invalid parameter type");
    this._syncCallbacks.push(cb);
  }

  update(index, value) {
    if (value === null || value === undefined) throw Error("State is invalid");
    else {
      this._io.emit("list-update", {
        name: this._stateName,
        rev: this._state.rev,
        state: { value, index }
      });
    }
  }

  remove(index) {
    if (isNaN(index)) throw Error("Index is invalid");
    else if (this._state.data[index]) {
      this._io.emit(
        "list-remove" /* TODO: for version 1, change this name to list-remove */,
        {
          name: this._stateName,
          rev: this._state.rev,
          state: { index }
        }
      );
    } else throw Error("There's no item at the specified index");
  }

  add(value) {
    if (value === null || value === undefined) throw Error("State is invalid");
    else {
      this._io.emit(
        "list-add" /* TODO: for version 1, change this name to list-add */,
        {
          name: this._stateName,
          rev: this._state.rev,
          state: value
        }
      );
    }
  }

  //resets state to new value
  set(state) {
    if (
      state.constructor.name !== "Array" ||
      state === null ||
      state === undefined
    ) {
      throw Error("State is invalid");
    } else if (state.length < 1) {
      throw Error("State cannot be an empty array");
    } else {
      this._io.emit("reset-list", {
        name: this._stateName,
        rev: this._state.rev,
        state: state
      });
    }
  }

  getAll() {
    return this._state.data;
  }

  getByIndex(index) {
    return this._state.data[index];
  }
}
